<template>
  <div>
    <b-card title=" Statistiques des préalertes par couleur">
      <!-- input search -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Recherche</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Recherche"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="prealertcolumns"
        :rows="stateTable"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="detailPrealert(props.row)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Détail</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else-if="props.column.field === 'rouge'">
            <b-badge
              v-if="props.row.rouge >= 1"
              :variant="statusVariant('Danger')"
            >
              <h3 style="color: white;">
                {{ props.row.rouge }}
              </h3>

            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'orange'">
            <b-badge
              v-if="props.row.orange >= 3"
              :variant="statusVariant('Danger')"
            >
              <h3 style="color: white;">
                {{ props.row.orange }}
              </h3>

            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'createdAt'">
            {{ getDate(props.row.createdAt) }}
          </span>
          <span v-else-if="props.column.field === 'createdBy'">
            <p>{{ `${props.row.createdBy.lastName}  ${props.row.createdBy.firstName} ` }}</p>
            <!-- {{ getDate(props.row.createdBy) }} -->
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','20','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BPagination, BFormGroup, BFormInput, BDropdown, BDropdownItem, BFormSelect, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import store from '@/store/index'
import prealertMixin from '../@core/mixins/prealertMixin'
import baliseMixin from '../@core/mixins/baliseMixin'

export default {
  components: {
    BCard,
    BPagination,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  mixins: [prealertMixin, baliseMixin],
  data() {
    return {
      pageLength: 10,
      dir: false,
      prealertcolumns: [

        {
          label: 'Balise',
          field: 'balise',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par balise',
          },
        },
        {
          label: 'Village',
          field: 'balise.zone.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par balise',
          },
        },
        {
          label: 'Vert',
          field: 'vert',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par auther',
          },
        },
        {
          label: 'Jaune',
          field: 'jaune',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par auther',
          },
        },
        {
          label: 'Orange',
          field: 'orange',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par auther',
          },
        },
        {
          label: 'Rouge',
          field: 'rouge',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par auther',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      baliseList: [],
      prealertList: [],
      stateTable: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */

        Danger     : 'danger',
        Warning     : 'warning',

        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    try {
      const prealertResponse = await this.$store.dispatch('mainAppStore/fetchPrealertesListItems')
      this.prealertList = prealertResponse.data

      const baliseResponse = await this.$store.dispatch('mainAppStore/fetchBaliseListItems')
      this.baliseList = baliseResponse.data

      this.getStates()
    } catch (error) {
      console.error(err.response)
    }
  },
  methods: {
    getStates() {
      console.log('toto est la')
      let stateOb = {}
      this.baliseList.forEach(element => {
        let prealert = null

        let countVert = 0
        let countJaune = 0
        let countOrange = 0
        let countRouge = 0
        for (let index = 0; index < this.prealertList.length; index++) {
          prealert = this.prealertList[index]
          if (prealert.balise._id === element.id) {
            if (prealert.code === 'VERT') {
              countVert++
            }
            if (prealert.code === 'JAUNE') {
              countJaune++
            }
            if (prealert.code === 'ORANGE') {
              countOrange++
            }
            if (prealert.code === 'ROUGE') {
              countRouge++
            }
          }
        }

        stateOb = {
          date: prealert.createdAt,
          balise: element.hameau,
          village: element.zone,
          vert: countVert,
          jaune: countJaune,
          orange: countOrange,
          rouge: countRouge,

        }
        this.stateTable.push(stateOb)
      })

      console.log(this.stateTable)
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
